#body {
	font-family: 'Noto Sans Japanese';
  	font-weight: 700;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.share_story_p1_section {
	margin-top: 7vw;;
	width: 100%;
	height: 85vw;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.mb_share_story_p1_section {
	margin-top: 20vw;
	width: 100%;
	height: 213.84vw;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.share_story_p1_bg {
	width: 100%;
}




.input_word {
	margin-top: -22.89vw;
	width: 51.14vw;
	height: 2.71vw;
	padding: 1vw 2vw;
	margin-left: auto;
	margin-right: auto;

	background-color: transparent;
	border: none;

	font-size: 1.4vw;
	color: var(--main-color);
	font-family: 'Noto Sans Japanese';
  	font-weight: 700;
}
.mb_input_word {
	margin-top: -54.2vw;
	width: 78vw;
	height: 7.3vw;
	padding: 3vw 5vw;
	margin-left: auto;
	margin-right: auto;

	background-color: transparent;
	border: none;

	font-size: 4.4vw;
	color: var(--main-color);
	font-family: 'Noto Sans Japanese';
  	font-weight: 700;
}
.input_word:focus {
	border: none;
}



.next_btn {
	margin-top: 8.86vw;
	margin-left: auto;
	margin-right: auto;
}
.next_btn img{
	width: 16.42vw;
}
.mb_next_btn {
	margin-top: 20.6vw;
	margin-left: auto;
	margin-right: auto;
}
.mb_next_btn img{
	width: 30.6vw;
}













